import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faAddressCard,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';

const ContactFloat = () => {
  // const formRef = useRef(null);

  const handleFormButtonClick = () => {
    const formElement = document.querySelector('#form');
    const navbarHeight = 80;

    if (formElement) {
      const offset = formElement.offsetTop - navbarHeight;
      setTimeout(() => {
        window.scrollTo({ top: offset, behavior: 'smooth' });
      }, 100); // Add a slight delay (e.g., 100 milliseconds)
    }
  };

  return (
    <div>
      <ul className='fixed flex-col flex z-50 bottom-96 sm:bottom-72 -left-1 md:left-2 '>
        <li className=''>
          <button
            className='cursor-pointer pb-4 '
            aria-label='Phone Icon - Call Button'
          >
            <a href='tel:9314343650' aria-label='Phone Icon - Call Button'>
              <FontAwesomeIcon
                icon={faPhone}
                style={{ height: '30px', width: '50px' }}
                className='hover:text-sky-400 w-12'
              />
            </a>
          </button>
        </li>
        <li>
          <button
            className='cursor-pointer pb-4'
            aria-label='Email Icon - Email Button'
          >
            <a
              href='mailto:info@tnprintworks.com'
              aria-label='Email Icon - Email Button'
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ height: '30px', width: '50px' }}
                className='hover:text-sky-400'
              />
            </a>
          </button>
        </li>
        <li>
          <button
            className='cursor-pointer'
            aria-label='Form Icon - Fill out a form to contact us'
            onClick={handleFormButtonClick}
          >
            <a
              href='#form'
              aria-label='Form Icon - Fill out a form to contact us'
            >
              <FontAwesomeIcon
                style={{ height: '30px', width: '50px' }}
                icon={faAddressCard}
                className='hover:text-sky-400'
              />
            </a>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ContactFloat;
