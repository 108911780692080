import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faSquareInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <div className=' mt-2 bg-whiteBackground'>
      <footer className='p-1'>
        <p className='flex justify-center text-lg font-bold pb-2'>
          TN Printworks LLC
        </p>
        <ul className='flex flex-nowrap justify-center pb-2'>
          <li>
            <button
              className='cursor-pointer'
              aria-label='Email icon - click to email us'
            >
              <a
                href='mailto:info@tnprintworks.com'
                aria-label='Email icon - click to email us'
              >
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ height: '30px', width: '50px' }}
                  className='mr-3 hover:text-sky-400'
                />
              </a>
            </button>
          </li>
          <li>
            <button aria-label='Instagram icon - click to see our account on instagram'>
              <a
                href='https://www.instagram.com/tnprintworks/'
                aria-label='Instagram icon - click to see our account on instagram'
              >
                <FontAwesomeIcon
                  icon={faSquareInstagram}
                  style={{ height: '30px', width: '50px' }}
                  className='mr-3 hover:text-instagram'
                />
              </a>
            </button>
          </li>
          <li>
            <button aria-label='Facebook icon - click to see our account on facebook'>
              <a
                href='https://www.facebook.com/tnprintworks'
                aria-label='Facebook icon - click to see our account on facebook'
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  style={{ height: '30px', width: '50px' }}
                  className='hover:text-facebook'
                />
              </a>
            </button>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Footer;
