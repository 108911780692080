import React from 'react';
import longCropped from '../images/tnprintworks-logo-long-cropped.webp';

const Navigation = () => {
  // const formRef = useRef(null);
  const handleFormButtonClick = () => {
    const formElement = document.querySelector('#contact');
    const navbarHeight = 60;

    if (formElement) {
      const offset = formElement.offsetTop - navbarHeight;
      setTimeout(() => {
        window.scrollTo({ top: offset, behavior: 'smooth' });
      }, 100); // Add a slight delay (e.g., 100 milliseconds)
    }
  };
  return (
    <>
      <nav className='flex items-center sm:justify-between bg-whiteBackground fixed top-0 left-0 right-0 z-50'>
        <div className='flex items-center flex-shrink-0 text-white'>
          <img
            src={longCropped}
            width={250}
            height={80}
            alt='logo'
            className='fill-current'
          />
        </div>

        <div className=' flex items-center  lg:flex ml-6 '>
          <div className='text-2xl lg:flex-grow'>
            <a
              aria-label='Contact button on navigation bar'
              href='#contact'
              className=' text-black block text-2xl  lg:inline-block lg:mt-0 hover:text-white mr-4 font-bold  sm:3xl'
              onClick={handleFormButtonClick}
            >
              Contact
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
