import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Services = () => {
  const differentServices = [
    {
      serviceName: 'SCREENPRINTING',
      alt: 'Screen printed t-shirt on machine',
      image: require('../images/printscreen.webp'),
      description: '',
    },
    {
      serviceName: 'EMBROIDERY',
      alt: 'Embroidered hat with TN Printworks logo',
      image: require('../images/embroidery.webp'),
      description: '',
    },
  ];
  return (
    <div>
      <h2 className='flex mt-4 mb-4 text-4xl font-bold place-content-center text-white'>
        SERVICES
      </h2>
      <div className='grid grid-cols-1 sm:grid-cols-2 w-3/5 place-content-center m-auto  rounded-lg'>
        {differentServices.map((service, i) => (
          <div className='mt-3  sm:pr-8  rounded-lg' key={service.serviceName}>
            <h3 className='font-bold text-lg sm:text-xl mb-4 pb-1 bg-whiteBackground m-auto place-content-center flex rounded-lg'>
              {service.serviceName}
            </h3>
            <LazyLoadImage
              className='w-full h-full bg-cover bg-fixed place-content-center pb-12 rounded-lg'
              src={service.image}
              alt={service.alt}
              width={150}
              height={150}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
