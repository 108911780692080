import React from 'react';
import SlideShow from './SlideShow';

const Hero = () => {
  return (
    <div className='grid grid-cols-1 eightHundred:grid-cols-2 w-10/12 place-content-center m-auto mb-4 mt-28 '>
      {/* h-4/5  */}
      <SlideShow className='' />
      <div className='border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b-lg sm:rounded-r-lg sm:rounded-bl-none p-4 flex flex-col justify-between leading-normal opacity-80 border-none pr-2 pl-5 sm:pl-8 sm:pr-8 md:pr-1 md:pl-4 '>
        <div className='mb-2 mt-6 '>
          <p className=' text-lg sm:text-xl pb-5'>
            <b>TNPrintworks</b> is a custom apparel Printing & Embroidery
            company shipping nationwide. With over 15 years experience, our
            focus is on quality products and individual customer service.
          </p>
          <p className=' text-lg sm:text-xl pb-5'>
            Along with traditional inks, we are experienced with discharge and
            specialty products like <b>metallic and shimmer inks</b>. With
            hundreds of different styles for men, children, and women; we’d love
            to help you with your project and earn our place as your production
            team by ensuring your satisfaction
          </p>
          <p className=' text-lg sm:text-xl pb-5'>
            We’re located in Middle TN, but <b>ship anywhere</b> in the
            Continental USA. If you’ve got any questions at all,{' '}
            <a
              href='mailto:info@tnprintworks.com'
              className='underline text-stone-500	hover:text-sky-400'
              aria-label='Email us text with hyperlink to send an email'
            >
              email us
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
