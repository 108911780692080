import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationDot,
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import squareCropped from '../images/tnprintworks-logo-square-cropped.webp';
import supportLocal from '../images/supportLocal.webp';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ContactForm = () => {
  // const serviceId = process.env.SERVICE_ID;
  // const templateId = process.env.TEMPLATE_ID;
  // const publicKey = process.env.SERVICE_ID;

  const [buttonState, setButtonState] = useState('Send Message');

  const [disabled, setDisabled] = useState(false);

  const formik = useFormik({
    //we have created our initailValues object in a format EmailJS accepts
    initialValues: {
      from_name: '', //user name
      to_name: process.env.REACT_APP_ADMIN_EMAIL, //email id of the admin
      subject: '', // phone
      reply_to: '', // user email
      message: '', // message of email
    },
    validationSchema: Yup.object({
      from_name: Yup.string().required('* Name field is required'),
      subject: Yup.string().required('* Subject field is required'),
      reply_to: Yup.string()
        .email('Invalid email address')
        .required('* Email field is required'),
      message: Yup.string().required('* Message field is required'),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log('values', values);
      try {
        emailjs
          .send(
            'service_7jr1len',
            // 'service_sh69fxo',
            'template_kgnqqxs',
            values,
            'CVCbrd9t9-bun5XaC'
          )
          .then(() => {
            console.log('email sent');
            setButtonState(
              'Thank you for your message! We will get back to you as soon as possible.'
            );
            setSubmitting(false);
            resetForm();
            setDisabled(true);
          });
      } catch {
        setButtonState('Send Email');
        setSubmitting(false);
      }
    },
  });

  return (
    <div id='contact' className=''>
      <div className='flex min-h-full flex-col justify-center px-6 my-7 lg:px-8  place-content-center m-auto'>
        <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
          <h2 className='mt-4 mb-8 flex place-content-center text-4xl font-bold text-white'>
            CONTACT
          </h2>
          <LazyLoadImage
            className='mx-auto mb-5'
            src={squareCropped}
            alt='Your Company'
            width='200'
            height='200'
          />
        </div>
        <div className='bg-whiteBackground w-11/12 m-auto place-content-center rounded-lg'>
          <div className=' grid grid-cols-1 md:grid-cols-2'>
            {/* Text Contact */}
            <container className='w-auto mt-5 mb-3 pr-2 pl-5 sm:ml-5 sm:pr-8 md:pr-1 md:pl-4'>
              <div>
                <h3 className='text-lg sm:text-xl font-bold'>
                  We look forward to hearing from you!
                </h3>
                <br></br>
                <p className='text-base sm:text-lg'>
                  If you don’t know what you want, that’s ok! Just let us know
                  what you’re curious about and one of us will get back to you
                  asap. We have accounts with all major wholesalers and can help
                  you shop for the perfect garment while discussing what medium
                  would best meet your needs.
                </p>
                <br></br>
                <p className='text-base sm:text-lg'>
                  If you know exactly what you want, that’s also great! We can
                  find you the best price available from one of our many
                  wholesalers, or you can bring us the goods you already have.
                </p>
              </div>
            </container>

            {/* FORM */}
            <div id='form'>
              <div className='pl-4 pr-4 mt-5 mb-5 md:pr-8 md:pl-1 md:place-content-center lg:place-content-center md:ml-6 lg:ml-6 md:mr-12 sm:mx-auto sm:w-full md:pt-10'>
                <form className='space-y-3' onSubmit={formik.handleSubmit}>
                  <div>
                    <label
                      htmlFor='from_name'
                      className='block text-base font-medium leading-6 text-gray-900'
                    />
                    <div className='mt-2'>
                      <input
                        id='from_name'
                        name='from_name'
                        type='text'
                        autoComplete='off'
                        placeholder='Full Name'
                        onChange={formik.handleChange}
                        value={formik.values.from_name}
                        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6 text-base sm:text-lg'
                      />
                    </div>
                    <div
                      className={`expandable ${
                        formik.touched.from_name && formik.errors.from_name
                          ? 'show'
                          : ''
                      }`}
                    >
                      {formik.errors.from_name}
                    </div>
                  </div>
                  <label
                    htmlFor='subject'
                    className='block text-base font-medium leading-6 text-gray-900'
                  />
                  <div className='mt-2'>
                    <input
                      id='subject'
                      name='subject'
                      type='number'
                      autoComplete='off'
                      placeholder='Phone Number'
                      onChange={formik.handleChange}
                      value={formik.values.subject}
                      className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6 text-base sm:text-lg'
                    />
                  </div>
                  <div
                    className={`expandable ${
                      formik.touched.subject && formik.errors.subject
                        ? 'show'
                        : ''
                    }`}
                  >
                    {formik.errors.subject}
                  </div>
                  <label
                    htmlFor='reply_to'
                    className='block text-base font-medium leading-6 text-gray-900'
                  />
                  <div className='mt-2'>
                    <input
                      id='reply_to'
                      type='email'
                      name='reply_to'
                      placeholder='Email Address'
                      autoComplete='off'
                      onChange={formik.handleChange}
                      value={formik.values.reply_to}
                      className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-base sm:text-lg sm:leading-6'
                    />
                  </div>
                  <div
                    className={`expandable ${
                      formik.touched.reply_to && formik.errors.reply_to
                        ? 'show'
                        : ''
                    }`}
                  >
                    {formik.errors.reply_to}
                  </div>
                  <label
                    htmlFor='message'
                    className='block text-base font-medium leading-6 text-gray-900'
                  />
                  <div className='mt-2'>
                    <textarea
                      id='message'
                      name='message'
                      placeholder='What can we do for you?'
                      autoComplete='off'
                      onChange={formik.handleChange}
                      value={formik.values.message}
                      className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-base sm:text-lg sm:leading-6'
                    />
                  </div>
                  <div
                    className={`expandable ${
                      formik.touched.message && formik.errors.message
                        ? 'show'
                        : ''
                    }`}
                  >
                    {formik.errors.message}
                  </div>
                  <div
                    className='col-12'
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    <button
                      aria-label='Send message button to send us a completed form'
                      disabled={disabled}
                      type='submit'
                      className='bg-transparent hover:bg-cyan text-gray-500 font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded text-base sm:text-lg'
                    >
                      <span>{buttonState}</span>
                    </button>
                  </div>
                  <div className='col-12 form-message'>
                    <span
                      id='output'
                      className='output_message text-center text-uppercase'
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className=' grid grid-cols-1 md:grid-cols-2
      '
          >
            <container className='flex w-auto m-auto place-content-center md:mb-5 mt-2 mr-2 ml-2'>
              <img
                src={supportLocal}
                width='200'
                height='200'
                alt='Support Local'
                className='sm'
              />
            </container>
            <container className='w-auto mt-10 mb-6 m-auto md:mb-5'>
              <div>
                <a
                  className='text-sm tablet:text-base block hover:text-sky-400'
                  href='mailto:info@tnprintworks.com'
                  aria-label='Email Icon - Email Button'
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    width={30}
                    height={30}
                    className='hover:text-sky-400'
                  />
                  info@tnprintworks.com
                </a>
                <a
                  className='text-sm tablet:text-base block hover:text-sky-400'
                  href='tel:9314343650'
                  aria-label='Phone number - Call Button'
                >
                  <FontAwesomeIcon
                    icon={faPhone}
                    width={30}
                    height={30}
                    className='hover:text-sky-400'
                  />
                  931-434-3650
                </a>
                <p className='text-sm tablet:text-base block'>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    width={30}
                    height={30}
                    className='hover:text-sky-400'
                  />
                  Manchester, TN
                </p>
              </div>
            </container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
