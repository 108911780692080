import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Gallery = () => {
  const imagesGallery = [
    {
      image: require('../images/tshirt-hat.webp'),
      alt: '"HWY 280" tshirt and hat',
    },
    {
      image: require('../images/hoodie.webp'),
      alt: 'Black apron and t-shirt with printed logo',
    },
    {
      image: require('../images/tshirt10.webp'),
      alt: 'White printed t-shirts',
    },
    {
      image: require('../images/hats1.webp'),
      alt: 'TN Printworks embroidered hats',
    },
    {
      image: require('../images/tshirt6.webp'),
      alt: 'Black t-shirts "Allen Stone Seattle"',
    },
    {
      image: require('../images/tshirt8.webp'),
      alt: 'T-shirt for Fellowship of Student Ministries',
    },
    {
      image: require('../images/tshirt9.webp'),
      alt: 'Here to pray, here to serve printed t-shirts',
    },
    {
      image: require('../images/tshirt1.webp'),
      alt: 'Can holders with printed logo and phrases',
    },
    {
      image: require('../images/bag2.webp'),
      alt: 'Yellow printed bag',
    },
    {
      image: require('../images/tshirt12.webp'),
      alt: 'Black t-shirts printed with "Girl DJs Rock"',
    },
    {
      image: require('../images/tshirt7.webp'),
      alt: 'White t-shirt for "Rush Camp"',
    },
    {
      image: require('../images/hats3.webp'),
      alt: 'Faces printed on a hat',
    },
    {
      image: require('../images/tshirt13.webp'),
      alt: 'T-shirts for "Jadon Parker"',
    },
    {
      image: require('../images/tshirt4.webp'),
      alt: '"Girls of Nashville" printed t-shirts',
    },

    {
      image: require('../images/img1.webp'),
      alt: '"Nigerls" printed hats',
    },
    {
      image: require('../images/tshirt11.webp'),
      alt: '"Boxing Club" printed t-shirt',
    },
    {
      image: require('../images/masks.webp'),
      alt: 'Printed face masks',
    },
    {
      image: require('../images/umbrella.webp'),
      alt: 'Screen printed patio umbrella',
    },
    {
      image: require('../images/notebook.webp'),
      alt: 'Printed notebook and pen',
    },
    {
      image: require('../images/bag1.webp'),
      alt: 'White printed bag',
    },
    {
      image: require('../images/hats2.webp'),
      alt: '"Dog The Bounty Hunter" printed hat',
    },
  ];
  return (
    <div>
      <h2 className='flex mt-10 mb-4 text-xl tablet:text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold place-content-center m-auto'>
        <container className='bg-white p-5 pb-7'>
          <i>Small Shop </i> &nbsp; | &nbsp; <i>BIG Quality</i>
        </container>
      </h2>
      <div>
        <div className='columns-2 md:columns-3 lg:columns-4 w-4/5 m-auto'>
          {imagesGallery.map((image, i) => (
            <div
              key={image.alt}
              className="relative mb-2 -mr-2 before:content-[''] before:rounded-lg  "
            >
              <LazyLoadImage
                className='w-full rounded-md'
                src={image.image}
                alt={image.alt}
                width={250}
                height={150}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
