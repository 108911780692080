import Navigation from './components/Navigation';
import Footer from './components/Footer';
import ContactFloat from './components/ContactFloat';
import Gallery from './components/Gallery';
import Hero from './components/Hero';
import Services from './components/Services';
import ContactForm from './components/ContactForm';
import './styles/styles.css';

export default function App() {
  return (
    <main className='flex min-h-screen flex-col justify-between '>
      <div className='bgOpacity'></div>
      <Navigation />
      <ContactFloat />
      <Hero />
      <Services />
      <Gallery />
      <ContactForm />
      <Footer />
    </main>
  );
}
