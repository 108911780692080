import React from 'react';
import { useEffect, useState } from 'react';

const SlideShow = () => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    let int = setInterval(() => {
      setPage((prev) => (prev + 1 >= 8 ? 1 : prev + 1));
    }, 2000);

    return () => clearInterval(int);
  }, []);

  const imagesSlideShow = require(`../images/img${page}.webp`);
  return (
    <div className='relative flex h-[calc(100vh-70px)] w-full flex-col justify-center items-center'>
      <div className='absolute w-full h-full'>
        <img
          alt='Tshirts and different prints slideshow'
          className='w-full h-full object-cover object-center rounded-t-lg sm:rounded-l-lg sm:rounded-tr-none'
          fill='true'
          src={imagesSlideShow}
        />
      </div>
    </div>
  );
};

export default SlideShow;
